
































































































import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Data } from '@vue/composition-api'
import { Filter } from '@movecloser/front-core/src/contracts/filter-parser'
import { FiltersConfig } from '@movecloser/front-core/lib/contracts/filter-parser'

import { AbstractDrawer } from '../../../shared/organisms/AbstractDrawer'
import { AccordionItem } from '../../../../dsl/atoms/Accordion'
import { FilterParamConfig, NavigationItem, TitleData } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import { FilterBadges } from '../FilterBadges'
import {
  FILTERS_DRAWER_COMPONENT_CONFIG_MAP,
  FILTERS_DRAWER_COMPONENT_KEY,
  FiltersDrawerMode
} from './FiltersDrawer.config'
import { FiltersDrawerProps } from './FiltersDrawer.contracts'
import { FilterList } from '../FilterList'
import { FilterListProps } from '../FilterList/FilterList.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<FiltersDrawer>({
  name: 'FiltersDrawer',
  components: { FilterBadges },
  created () {
    this.initSelectedFilters()
    this.initShownCategoryNavItems()
    this.config = this.getComponentConfig(
      FILTERS_DRAWER_COMPONENT_KEY,
      FILTERS_DRAWER_COMPONENT_CONFIG_MAP
    )
  }
})
export class FiltersDrawer extends Mixins<AbstractDrawer<FiltersDrawerProps>, StructureConfigurable>(
  AbstractDrawer,
  StructureConfigurable
) {
  public filters: FiltersConfig = this.payload.filters ?? {}

  public isExpanded: boolean = false
  public listControlsVisible: boolean = true
  public selected: AnyObject = {}
  public shownCategoryNav: NavigationItem[] = []
  public SHOWN_CATEGORY_NAV_ITEMS = 6
  public open: boolean = false

  public get accordionItems (): AccordionItem[] {
    return this.filterParams
      .filter((param) => this.payload.hideListControlParamsOnMobile ? !!param.isAttribute : true)
      .map((item: FilterParamConfig) => {
        const props: FilterListProps = {
          isMulti: item.isMulti,
          items: item.options,
          filter: this.filters[item.queryParam],
          queryParam: item.queryParam,
          sort: item.sort
        }

        return {
          id: `${item.queryParam}-${Object.keys(item.options).length}`,
          label: Object.keys(this.selected).includes(item.queryParam) &&
          this.selected[item.queryParam] !== null
            ? `${item.label} (${this.selected[item.queryParam]})`
            : item.label ?? '',
          content: {
            component: FilterList,
            props: props as unknown as Data,
            on: {
              'update:filter': (value: Filter) => {
                this.updateFilter(item.queryParam, value)
                if (this.hasInstantApply) {
                  this.applyFilters()
                }
              }
            }
          },
          classname: [
            Object.keys(this.selected)
              .includes(item.queryParam) && this.selected[item.queryParam] !== null
              ? '--hasIdentifier' : '', item.isOpen ? '--always-open' : ''
          ]
        }
      })
  }

  public get sorting (): FilterParamConfig | undefined {
    return this.filterParams.find((item: FilterParamConfig) => item.queryParam === 'sort')
  }

  public get sortingOptions (): { label: string; value: string }[] {
    return Object.keys(this.sorting?.options ?? {}).map(key => ({
      value: key,
      label: this.sorting?.options?.[key] ?? ''
    }))
  }

  public get sortLabel (): string {
    return this.$t('front.shared.filters.sort').toString() + ' ' + this.sortingOptions?.find((option) => option.value === this.filters.sort)?.label ?? ''
  }

  public get isOpen (): boolean {
    return this.open
  }

  public get applyingFilters (): boolean {
    return this.$store.getters['shared/areFiltersApplying']
  }

  public get areControlsVisible (): boolean {
    return this.listControlsVisible
  }

  public get drawerTitle (): TitleData | undefined | null {
    return this.payload.title
  }

  public get filterParams (): FilterParamConfig[] {
    return this.$store.getters['shared/getFilterParams']
  }

  public get hasCategoryTitle (): boolean {
    return this.getConfigProperty<boolean>('hasCategoryTitle')
  }

  public get hasInstantApply (): boolean {
    return this.mode === FiltersDrawerMode.InstantApply ||
      this.mode === FiltersDrawerMode.InstantApplyAndClose
  }

  public get hasSubmit (): boolean {
    return this.mode !== FiltersDrawerMode.InstantApplyAndClose
  }

  public get isListExpanded (): boolean {
    return this.isExpanded
  }

  public get mode (): FiltersDrawerMode {
    return this.getConfigProperty<FiltersDrawerMode>('mode')
  }

  public get navData (): NavigationItem[] | undefined | null {
    return this.payload.navData
  }

  public get shownCategoryNavItems (): NavigationItem[] {
    return this.shownCategoryNav
  }

  public get shouldRenderEmptyState (): boolean {
    return this.getConfigProperty<boolean>('shouldRenderEmptyState')
  }

  public applyFilters (): void {
    this.$store.dispatch('shared/applyFilters', this.filters)

    if (this.mode === FiltersDrawerMode.InstantApplyAndClose) {
      this.close()
    }

    this.$store.commit('shared/toggleFiltersState', true)
  }

  public submit (): void {
    if (this.mode === FiltersDrawerMode.Default) {
      this.$store.dispatch('shared/applyFilters', this.filters)
    }

    this.close()
  }

  /**
   * Expand list with category nav links
   */
  public expandCategoryNav (): void {
    this.isExpanded = true

    if (this.navData && this.navData.length > 0) {
      this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }

  /**
   * Close list with category nav links
   */
  public closeCategoryNav (): void {
    this.isExpanded = false

    if (this.navData && this.navData.length > 0) {
      if (this.navData.length < 6) {
        this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length
      } else {
        this.SHOWN_CATEGORY_NAV_ITEMS = 6
      }

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }

  public onRemoveFilter (queryParam: string, value: string) {
    this.filters = this.payload.removeFilter(
      this.filters,
      this.filterParams,
      queryParam,
      value
    ) ?? {}
    if (this.hasInstantApply) {
      this.applyFilters()
    }
  }

  public onClearFilters () {
    this.filters = this.payload.clearFilters(this.filterParams, this.filters)
    this.initSelectedFilters()

    if (this.hasInstantApply) {
      this.applyFilters()
    }
  }

  public updateFilter (param: string, value: Filter) {
    this.filters = {
      ...this.filters,
      [param]: value
    }
    this.selected = {
      ...this.selected,
      [param]: this.countSelected(this.filters[param])
    }
  }

  protected countSelected (value: Filter): number | null {
    return Array.isArray(value) && value.length > 0 ? value.length : null
  }

  protected initSelectedFilters (): void {
    for (const [key, value] of Object.entries(this.filters)) {
      this.selected = {
        ...this.selected,
        [key]: this.countSelected(value)
      }
    }
  }

  /**
   * Compose initially shown category nav links
   * @protected
   */
  protected initShownCategoryNavItems (): void {
    if (this.navData && this.navData.length > 0) {
      if (this.navData.length < 6) {
        this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length

        /**
         * Hide list controls visibility
         */
        this.listControlsVisible = false
      } else {
        this.SHOWN_CATEGORY_NAV_ITEMS = 6
      }

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }

  @Watch('filters')
  protected onFiltersUpdate (): void {
    this.initSelectedFilters()
  }
}

export default FiltersDrawer
